// CarouselComponent.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import newsimg1 from "../../../assets/zigchain.png";
import newsimg2 from "../../../assets/excitingnews.png";
import newsimg3 from "../../../assets/partnership.png";
import Paragraph from '../Paragraph';

// Custom Next Arrow
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <span>→</span>
    </div>
  );
}

// Custom Previous Arrow
function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <span>←</span>
    </div>
  );
}

// Carousel Component
const CarouselComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div style={{ backgroundColor: "#40B74B" }} id="News">
      <Paragraph padding="40px 0 70px" className="textStroke" color="white" data-aos="zoom-in" textAlignLast="center" fontFamily="KB" textALign="center" fontSize={{ xs: "70px", md: "90px" }}>
        Pepay News
      </Paragraph>
      <div className="carousel-container fade-right-container" >
        <Slider {...settings}>
          <div data-aos="zoom-out-down" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0">
            <a href='https://x.com/Pepayoneth/status/1834608908821799279' target='blank' style={{ textDecoration: "none", color: "black" }}>
              <div className="carousel-item">
                <img src={newsimg1} alt="News 1" className="carousel-image" />
                <div className="carousel-info">
                  <p>Sep 14, 2024</p>
                  <h3>Virtual Zigchain X Pepay Card Guide 🐸</h3>
                </div>
              </div>
            </a>
          </div>

          <div data-aos="zoom-out-down" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0">
            <a href='https://x.com/Pepayoneth/status/1834538895280685266' target='blank' style={{ textDecoration: "none", color: "black" }}>

              <div className="carousel-item">
                <img src={newsimg2} alt="News 2" className="carousel-image" />
                <div className="carousel-info">
                  <p>Sep 13, 2024</p>
                  <h3>Exciting News From Pepay: Website V2 Development Underway! 🌐</h3>
                </div>
              </div>
            </a>
          </div>

          <div data-aos="zoom-out-down" data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0"> 
            <a href='https://x.com/Pepayoneth/status/1834270603517599843' target='blank' style={{ textDecoration: "none", color: "black" }}>

              <div className="carousel-item">
                <img src={newsimg3} alt="News 3" className="carousel-image" />
                <div className="carousel-info">
                  <p>Sep 12, 2024</p>
                  <h3>Partnership: @Zignaly X Pepay - #Zigchain Card Integration💳</h3>
                </div>
              </div>
            </a>

          </div>
        </Slider>
      </div>
    </div>
  );
};

export default CarouselComponent;
