import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import emotionStyled from "@emotion/styled";
import stakeimg from "../../../assets/zorint.png"
import pepayapp from "../../../assets/birdt.png"
import pepaygame from "../../../assets/soet.png"
import nft from "../../../assets/dannyt.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWrapper = emotionStyled(Box)`
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position-x: center;
    background-color: #39933C;
    padding: 80px 0 150PX;
    border-top:1px solid #000;
    border-bottom:1px solid #000;
    
`
const CustomGrid = emotionStyled(Grid)`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  
`;

const EcoImg = emotionStyled.img`
width:100%;
height:auto;
border-radius:0 0 9px 9px;
`;
const PoolContainer = emotionStyled.div`
background-color:#C8EE5B;
padding:0px 0 10px; 
border-radius: 14px; /* For rounded corners if needed */
border-bottom:2px solid black;
border-left:1px solid black;
border-right:1px solid black;

`;

const HowToBuy = () => {
    return (
        <SectionWrapper id="Team">
            <Container maxWidth="xxl" id="Howtobuy">
                <Container maxWidth="xl" >
                    <CustomGrid data-aos="zoom-in" item xs={12}><Typography className="textStroke" variant='h3' fontFamily="kb" textAlign='center' color="#fff" textTransform="camelcase" fontSize={{ xs: "50px", sm: "80px" }} >
                        TEAM</Typography></CustomGrid>

                    <Grid container gap={2} justifyContent="center">
                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0"
                            ><EcoImg alt="pepay Image" src={stakeimg} />
                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "30px", sm: "35px" }}  >
                                    Zorin Zyno
                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 10px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "20px", sm: "22px" }}  >
                                    Core Team Member/<br />
                                    Lead Dev
                                </Typography>
                            </PoolContainer>
                        </Grid>

                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0"
                            ><EcoImg src={pepayapp} alt="pepay Image" />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "30px", sm: "35px" }}  >
                                    Bird                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "20px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>

                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0"
                            ><EcoImg src={pepaygame} alt="pepay Image" />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "30px", sm: "35px" }}  >
                                    Soef
                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "20px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>

                        <Grid item xs={12} sm={6} md={5.8} lg={2.7} style={{ display: "flex", padding: "20px 0" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0"
                            ><EcoImg src={nft} alt="pepay Image" />

                                <Typography style={{ margin: "0", textAlign: "left", padding: "22px 10px 0px 20px", textWrap: "wrap" }} fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "30px", sm: "35px" }}  >
                                    Danny                                </Typography>
                                <Typography style={{ margin: "0", textAlign: "left", padding: "0px 10px 0px 20px", textWrap: "wrap" }} fontFamily="km" textAlign="center" whiteSpace="nowrap" color="#000" fontSize={{ xs: "20px", sm: "22px" }}  >
                                    Core Team Member
                                </Typography>
                            </PoolContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;