import React, { useEffect } from 'react'
import { PageWrapper } from '../../Styles/style'
import HeroSection from '../../Sections/S1_Hero'
import AboutSection from '../../Sections/S2_About'
import Tokenomics from '../../Sections/S5_Roadmap'
import DisclaimerSection from '../../Sections/S7_Disclaimer'
import Marquee from 'react-fast-marquee'
import Paragraph from '../../Components/Paragraph'
import { Box } from '@mui/material'
import HowToBuy from '../../Sections/S3_Ecosystem'
import Unisec from '../../Sections/S3_Unisec'
import Team from '../../Sections/S6_Team'
import Tokonew from '../../Sections/S4_Tokenomics'
import pepayimg from "../../../assets/pepaycoin.png"
import emotionStyled from '@emotion/styled'
import News from '../../Sections/S6_1_News'
import Aos from "aos";
import "aos/dist/aos.css";

const Wrap = emotionStyled.div`
position:relative;
`
const MainPage = (props) => {

  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <Wrap>
        <Box style={{ background: "#C8EE5B", padding: "0", margin: "0" }} m={{ xs: "0px 0 0", sm: "0px 0 0" }}>
          <Marquee style={{ border: "2px solid #000", margin: "0px 0", background: "#40B74B", width: "calc(100% + 40px)", left: "-20px", }}>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Pepay{" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}

            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Feels Good Man {" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Pepay{" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Feels Good Man {" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Pepay{" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Feels Good Man {" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Pepay{" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Feels Good Man {" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Pepay{" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" style={{ display: "flex", alignItems: "center" }} m={{ xs: "10px 10px 10px 5px", sm: "20px 10px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              Feels Good Man {" "}
              <img src={pepayimg} alt="Pepay Logo" className="logo-flip" /> {/* Flipping Logo */}
            </Paragraph>
          </Marquee>
          {/* ////////////////////////////////// */}
          <Marquee style={{ border: "2px solid #000", margin: "10px 0 0", background: "#40B74B", width: "calc(100% + 40px)", left: "-20px", }}>
            <Paragraph color="#000" textTransform="camelcase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "20px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="camelcase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "20px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="camelcase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "20px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="camelcase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "20px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!           </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
            <Paragraph color="#000" textTransform="uppercase" m={{ xs: "10px 20px", sm: "20px 30px" }} fontSize={{ xs: "16px", sm: "18px", md: "22px", lg: "30px" }} fontFamily="PSB">
              The Meme Coin That Puts Crypto In Your Pocket!
            </Paragraph>
          </Marquee>
        </Box>
      </Wrap>
      <AboutSection />
      <HowToBuy />
      <Tokonew />
      <Tokenomics />
      <Team />
      <News />
      <Unisec />
      <DisclaimerSection />
    </PageWrapper>
  )
}

export default MainPage