import emotionStyled from '@emotion/styled'
import React, { useEffect } from 'react'
import heroBg from "../../../assets/heroBg.png"
import heroBgmob from "../../../assets/herobgmob.png"
import { Box, Container, Grid, Typography } from '@mui/material'
import { uniSwap } from '../../../links'
import LockerMenu from '../../Components/LockerMenu'
import styled from '@emotion/styled'
import headinging from "../../../assets/heading.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});

const SectionWraper = emotionStyled(Box)`
    background-image:url(${heroBg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;
    min-height:100vh;
    // width:100%;
    display:flex;
    justify-content:center;
    position:relative;
    overflow:hidden;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
    text-align:center;
    @media screen and (max-width:599px){
 background-image:url(${heroBgmob});
}
`
const ImgHeading = styled('img')`
width: 100%;
height:auto;
max-width:650px;
`

const BUTTDDF = styled.button`
border-width: 0px;
background: #C8EE5B;
cursor:pointer;
font-family: kb;
    // width: calc(100% - 40px);
    color: #000;
    margin: 0px auto;
    padding: 10px 25px;
    text-align: center;
    border-radius:10px;
    BORDER:2PX SOLID BLACK;
font-size:20px;
    transition: all 0.2s ease;
    box-shadow: 0px 6px 0px 0px #000;
    // border: 2px solid black;
    :active{
        
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
        
    }
    `
    const Grids = emotionStyled(Grid)`
    display: flex;
    flex-flow: column;
    align-items: start;
    `
const HeroSection = () => {
    
    useEffect(() => {
        AOS.refresh();
      }, []); // Replace 'yourDependency' with the state or prop that changes
      

    return (

        <SectionWraper data-aos="fade-zoom-in"
        data-aos-offset="0" data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="300">
            <Container maxWidth="xl"  >
                <LockerMenu />

                <Grid container sx={{ display: "flex", minHeight: "80vh", justifyContent: "left", alignItems: { xs: "start", md: "start" } }}>
                    <Grids item xs={12} md={6} alignItems='start'>
                        <ImgHeading  data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0" alt="pepay image" src={headinging}/>
                            <Typography data-aos="zoom-in" data-aos-delay="600" data-aos-duration="900" data-aos-easing="ease-in-out"  data-aos-offset="0" fontFamily="kb" textAlign="left" whiteSpace="nowrap" color="#fff" fontSize={{ xs: "28px", sm: "39px", md: "50px" }} p="0px 6px 10px" component={"span"}>
                            Feels Good Man!
                            </Typography>
                        <a data-aos="zoom-in" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="900" data-aos-offset="0" href={uniSwap} target="blank">
                            <BUTTDDF target="blank">Buy $PEPAY Now</BUTTDDF>
                        </a>
                    </Grids>
                    < Grid item xs={12} md={6} />
                </Grid>
            </Container>
        </SectionWraper>
    )
}

export default HeroSection