import React from "react";
import logo from "../../../../assets/logo.png";
import { DesktopMainMenu, MenuLink } from "../styles";

const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <a href="/" style={{padding:"6px 00 0"}}p="10px 0 0">
        <img src={logo} width="210" height='65' alt="Logo" />
      </a>
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
      </div>
    
    </DesktopMainMenu>
    
  );
};

export default DesktopMenu;
