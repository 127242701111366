import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import emotionStyled from "@emotion/styled";
import heroBg from "../../../assets/ecobg.png"
import logo from "../../../assets/logoeco.png"
import X from "../../../assets/X.png"
import logoZi from "../../../assets/logozi.png"
import ecostake from "../../../assets/ecostake.png"
import ecogame from "../../../assets/ecogame.png"
import ecoapp from "../../../assets/ecoapp.png"
import econft from "../../../assets/econft.png"
import ecosideimg from "../../../assets/ecosideimg.png"

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWrapper = emotionStyled(Box)`
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position-x: center;
    background-color: #39933C;
    padding: 80px 0 150PX;
    
`
const PepEcoCard = emotionStyled.img`
width:100%;
height:auto;
max-width:1130px;
`
const CustomGrid = emotionStyled(Grid)`
  display: flex;
  width: 100%;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  text-align: ${(props) => (props.tl ? props.tl : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  gap: ${(props) => (props.g ? props.g : "0")};
  
`;
const GridSWrap = emotionStyled(Grid)`
  background-image:url(${heroBg});
    background-size:cover;
    background-position: center;
    background-repeat:no-repeat;  
    justify-content:left;
    overflow:hidden;
    border-radius:20px;
  border:2px solid #000;
  padding:30px;
  
`;
const BUTTDDF = styled.button`
border-width: 0px;
background: #C8EE5B;
cursor:pointer;
font-family: kb;
    // width: calc(100% - 40px);
    color: #000;
    margin: 0px auto;
    padding: 10px 25px;
    text-align: center;
    border-radius:10px;
    BORDER:2PX SOLID BLACK;
font-size:20px;
    transition: all 0.2s ease;
    box-shadow: 0px 6px 0px 0px #000;
    // border: 2px solid black;
    :active{
        
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        transform: translateY(2px);
        
    }
        `

const CustomDiv = styled.div`
        display: flex; 
        align-items: center;
        justify-content: start;  // Center the images
        gap: 10px;  // Reduced gap for better spacing
        flex-wrap: wrap;
        flex-flow:row;
    `;

const LogoImg = emotionStyled.img`
        width: auto;
        max-width: 290px;  // Keep it close to the actual size
        height: auto;
        flex: 0 0 auto;  // Prevent image from shrinking beyond its max size
        @media screen and (max-width:790px){
            max-width: 200px;  // Keep it close to the actual size
    }
        @media screen and (max-width:590px){
            max-width: 150PX;  // Keep it close to the actual size
    }
        @media screen and (max-width:450px){
            max-width: 130PX;  // Keep it close to the actual size
    }
        @media screen and (max-width:400px){
            max-width: 100PX;  // Keep it close to the actual size
    }
            @media screen and (max-width:350px){
                max-width: 90PX;  // Keep it close to the actual size
        }
        `;

const LogoImgZI = emotionStyled.img`
        width: auto;
        max-width: 290px;  // Actual size of ZIGChain image
        height: auto;
        flex: 0 0 auto;
           @media screen and (max-width:790px){
            max-width: 200px;  // Keep it close to the actual size
    }
           @media screen and (max-width:590px){
            max-width: 150PX  // Keep it close to the actual size
    }
           @media screen and (max-width:450px){
            max-width: 130PX  // Keep it close to the actual size
    }
           @media screen and (max-width:350px){
            max-width: 90PX  // Keep it close to the actual size
    }
    `;

const Ximg = emotionStyled.img`
        width: auto;
        max-width: 30px;  // For the X symbol, small size
        height: auto;
        flex: 0 0 auto;
          @media screen and (max-width:450px){
            max-width: 20PX  // Keep it close to the actual size
    }
    `;


const EcoImg = emotionStyled.img`
      width:100%;
      height:auto;
    `;
const EcoWrap = emotionStyled.div`
      background-color:#8D8D8D;
      padding:0px 0 0px;
      border-radius:14px;
      position: relative;
    `;
const PoolContainer = emotionStyled.div`
    background-color:#8D8D8D;
      padding:0px 0 10px; 
      border-radius: 14px; /* For rounded corners if needed */
      border-bottom:2px solid black;
      border-left:1px solid black;
      border-right:1px solid black;
    
`;

const OverlayText = emotionStyled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0, black, transparent);
    padding: 113px 0;
`;

const Typographys = emotionStyled(Typography)`
  position:Absolute;
  left: 0;
    place-self: center;
    right: 0;
    bottom: 10px;

`;



const HowToBuy = () => {
    return (
        <SectionWrapper >
            <Container maxWidth="xxl" id="Merchandise">
                <Container maxWidth="xl" >
                    <CustomGrid data-aos="zoom-in"
                        data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0"
                        item xs={12}><Typography className="textStroke" padding="20px 0 40px" variant='h3' fontFamily="kb" textAlign='center' color="#fff" textTransform="camelcase" fontSize={{ xs: "50px", sm: "80px" }} >
                            Merchandise</Typography></CustomGrid>

                    <GridSWrap container sx={{ justifyContent: "LEFT", display: "flex", gap: { xs: "10px", lg: "20px" } }} >
                        <Grid xs={12} md={6} sx={{ justifyContent: "LEFT", }}  >
                            <CustomDiv >
                                <LogoImg alt="pepay image" src={logo} data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0" />
                                <Ximg  alt="pepay image" src={X} data-aos="zoom-in" data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0" />
                                <LogoImgZI alt="pepay image" src={logoZi} data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0" />
                            </CustomDiv>
                            <Typography style={{ margin: "0", textAlign: "left", padding: "40px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kr" textAlign="CENTER" whiteSpace="nowrap" color="#fff" fontSize={{ xs: "20px", sm: "30px" }}  >
                                PePay is now fully integrated with the Mastercard-powered @ZIGChain Card! Deposit PePay onto your card and spend at over 38M+ merchants across 180+ countries.
                                <br />
                                <br />
                                Whether you're buying groceries, shopping online, or dining out, you can now spend PePay just like traditional currency. No extra steps, no complications just seamless transactions.
                            </Typography>
                            <a target="blank" href="https://zigchain.mobilum.com/#/start">
                                <BUTTDDF data-aos="zoom-in">
                                    Get Your Card Now
                                </BUTTDDF>
                            </a>
                        </Grid>
                        <Grid xs={12} md={5} alignContent="center">
                            <PepEcoCard alt="pepay image" data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0" src={ecosideimg} />
                        </Grid>
                    </GridSWrap>
                    <Grid container gap={2} justifyContent="center">
                        <Grid item xs={12} sm={6} md={5.8} lg={2.9} style={{ justifyContent: "center", display: "flex", padding: "20px 0", gap: "10px" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0"
                            >
                                <EcoWrap>
                                    <EcoImg alt="pepay image" src={ecostake} />
                                    <OverlayText>
                                        <Typographys style={{ margin: "0", textAlign: "center", padding: "0px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#FFF" fontSize={{ xs: "20px", sm: "30px" }}  >
                                            Staking Pool

                                        </Typographys>
                                    </OverlayText>
                                </EcoWrap>
                                <Typography style={{ margin: "0", textAlign: "center", padding: "10px 0 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#4B4B4B" fontSize={{ xs: "16px", sm: "25px" }}  >
                                    coming soon
                                </Typography>
                            </PoolContainer>

                        </Grid>
                        <Grid item xs={12} sm={6} md={5.8} lg={2.9} style={{ justifyContent: "center", display: "flex", padding: "20px 0", gap: "10px" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0"
                            >
                                <EcoWrap>
                                    <EcoImg  alt="pepay image" src={ecoapp} />
                                    <OverlayText>
                                        <Typographys style={{ margin: "0", textAlign: "center", padding: "0px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#FFF" fontSize={{ xs: "20px", sm: "30px" }}  >
                                            Pepay App
                                        </Typographys>
                                    </OverlayText>
                                </EcoWrap>
                                <Typography style={{ margin: "0", textAlign: "center", padding: "10px 0 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#4B4B4B" fontSize={{ xs: "16px", sm: "25px" }}  >
                                    coming soon
                                </Typography>
                            </PoolContainer>

                        </Grid>
                        <Grid item xs={12} sm={6} md={5.8} lg={2.9} style={{ justifyContent: "center", display: "flex", padding: "20px 0", gap: "10px" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1100" data-aos-easing="ease-in-out" data-aos-delay="700" data-aos-offset="0"
                            >
                                <EcoWrap>
                                    <EcoImg alt="pepay image" src={ecogame} />
                                    <OverlayText>
                                        <Typographys style={{ margin: "0", textAlign: "center", padding: "0px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#FFF" fontSize={{ xs: "20px", sm: "30px" }}  >
                                            Pepay Game
                                        </Typographys>
                                    </OverlayText>
                                </EcoWrap>
                                <Typography style={{ margin: "0", textAlign: "center", padding: "10px 0 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#4B4B4B" fontSize={{ xs: "16px", sm: "25px" }}  >
                                    coming soon
                                </Typography>
                            </PoolContainer>

                        </Grid>
                        <Grid item xs={12} sm={6} md={5.8} lg={2.9} style={{ justifyContent: "center", display: "flex", padding: "20px 0", gap: "10px" }}>
                            <PoolContainer data-aos="fade-up"
                                data-aos-anchor-placement="center-bottom"
                                data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0"
                            >
                                <EcoWrap>
                                    <EcoImg alt="pepay image" src={econft} />
                                    <OverlayText>
                                        <Typographys style={{ margin: "0", textAlign: "center", padding: "0px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#FFF" fontSize={{ xs: "20px", sm: "30px" }}  >
                                            NFT Marketplace
                                        </Typographys>
                                    </OverlayText>
                                </EcoWrap>
                                <Typography style={{ margin: "0", textAlign: "center", padding: "10px 0 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kb" textAlign="center" whiteSpace="nowrap" color="#4B4B4B" fontSize={{ xs: "16px", sm: "25px" }}  >
                                    coming soon
                                </Typography>
                            </PoolContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </SectionWrapper>
    );
};

export default HowToBuy;