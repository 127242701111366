import emotionStyled from '@emotion/styled'
import React from 'react'
import pepjoinimg from "../../../assets/pepjoinimg.png"
import { Box, Container, Grid } from '@mui/material'
import Paragraph from '../../Components/Paragraph'

import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import twitter from "../../../assets/twitter.png";
import telegram from "../../../assets/telegram.png";
import { tg, x } from '../../../links';


// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});


const SOcialimg = emotionStyled.img`
width:77px;
height:85px;

`
const SectionWraper = emotionStyled(Box)`
    background-size:cover;
    background-color:#40B74B;
    // border-radius:30px;
    // min-height:auto;
    padding:180px 0 200px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`
const Paragraphs = emotionStyled(Paragraph)`
   @media screen and (max-width:899px){

    text-align:center;
    }
`
const Grids = emotionStyled(Grid)`
    background-color:#39933C;
    border-radius:20px;
    border:2px solid #000;
width:100%;

    align-items: center!important;
@media screen and (max-width:899px){
padding:20px 0 0 0;
    flex-wrap: wrap-reverse;
    jutfify-Content
    }
    `

const PpeImg = emotionStyled.img`
max-width:1900px;
  width: 100%;
  height: auto;
`;
const DivWeap = emotionStyled.div`
display:flex;
gap:10PX;
@media screen and (max-width:899px){
    justify-content: center;
}
`
const AboutSection = () => {
    return (
        <SectionWraper >
            <Container maxWidth="xl">
                <Grids container alignItems="center" sx={{alignItems:"center", alignContent:"center", display: "flex", justifyContent: "left"}}>
                    <Grid style={{display:"flex"}} item xs={12} md={6}>
                        <PpeImg alt="pepay image" src={pepjoinimg} data-aos="zoom-in" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="600" data-aos-offset="0" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paragraphs textAlign="left" className="textStroke" lineHeight="1" data-aos="zoom-in" fontFamily="kb" color="#fff" fontSize={{ xs: "45px", sm: "90px" }}>
                            Join The
                            Revolution!
                        </Paragraphs>
                        <br/>
                        <DivWeap style={{display:"flex",gap:"10PX"}}>
                            <a href={tg} target="blank" data-aos="zoom-in" data-aos-duration="1200" data-aos-easing="ease-in-out" data-aos-delay="800" data-aos-offset="0">
                                <SOcialimg src={telegram} alt="telegram" />
                            </a>
                        
                            <a href={x} target="blank" data-aos="zoom-in" data-aos-duration="1300" data-aos-easing="ease-in-out" data-aos-delay="1000" data-aos-offset="0">
                                <SOcialimg src={twitter} alt="twitter" />
                            </a>
                        </DivWeap>
                    </Grid>

                </Grids>
            </Container>
        </SectionWraper>
    )
}

export default AboutSection