import emotionStyled from '@emotion/styled'
import React from 'react'
import footerimg from "../../../assets/footer.png"
import { Box, Container, Grid } from '@mui/material'
import Paragraph from '../../Components/Paragraph'
import styled from '@emotion/styled'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
padding:300PX 0 50px;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
`
const ImgFot = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  top: -100px;
  left: 50%; /* Start positioning from the middle of the container */
  transform: translateX(-50%); /* Move the image to the center */
  max-width: ${props => props.mw ? props.mw : "300px"};
  display: ${props => props.disp && props.disp};
  @media (max-width: 599px) {
    display: ${props => props.disp599 && props.disp599};
  }
`;

const DisclaimerSection = () => {


    return (
        <>
            <SectionWraper>
                <Container maxWidth="md"  >
                    <ImgFot alt="pepay Image" src={footerimg} />
                    <Grid container sx={{ display: "flex", gap: "20px 0", justifyContent: "center", alignItems: { xs: "center", md: "start" } }}>
                        <Grid item xs={12}>
                            <Paragraph data-aos="zoom-in" fontFamily="km" fontSize={{ xs: "20px", sm: "40px" }}>
                                You Can Email Info@Pepay.Club To Provide
                                Feedback For Support Requests.
                            </Paragraph>
                            <Paragraph padding="20px 0 0" color="#fff" textAlign="center" fontFamily="km" >
                                Copyright 2024 | All Rights Reserved
                            </Paragraph>
                        </Grid>
                    </Grid>
                </Container>
            </SectionWraper>

        </>
    )
}

export default DisclaimerSection