import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import Paragraph from '../../Components/Paragraph';
import car from "../../../assets/car.png"
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import emotionStyled from '@emotion/styled';
import Carousel from '../S5_1_Roadmap';
// Initialize AOS
AOS.init({
  duration: 1200, // Duration of animations (optional)
  once: true, // Whether animation should happen only once - while scrolling down (optional)
  mirror: false, // Whether elements should animate out while scrolling past them (optional)
});
const SectionWraper = emotionStyled(Box)`
    background-size:cover;
    background-repeat:no-repeat;
    // background-color:#39933C;
    // min-height:100VH;
    width:100%;
    justify-content:center;
    align-items:center;
`


const PhasesSection = () => {
  return (
    <div style={{ textAlign: "center", backgroundColor: "#40B74B" }}>
      <img style={{ width: "100%", height: "auto" }} alt="pepay" src={car} />
      <SectionWraper id="Roadmap">
        <Container>
          <Paragraph className="textStroke" data-aos="zoom-in" fontFamily="kb" fontSize={{ xs: "50px", sm: "90px" }}>
            Roadmap
          </Paragraph>
          <Typography style={{ margin: "0", textAlign: "CENTER", padding: "40px 0", textWrap: "wrap" }} data-aos="fade-up" fontFamily="kr" textAlign="CENTER" whiteSpace="nowrap" color="#fff" fontSize={{ xs: "20px", sm: "30px" }}  >
            Pepay Coin features two distinct roadmaps to guide its development: V1 and V2. Each roadmap represents a key phase in the evolution of our project. V1 outlines the foundational steps we've taken to establish Pepay Coin, while V2 introduces future advancements and features designed to elevate the project to new heights. Explore both versions to see how we're driving progress and expanding our Merchandise!
          </Typography>
        </Container>
      </SectionWraper>
      <Carousel />
    </div>
  );
};

export default PhasesSection;
