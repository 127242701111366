import emotionStyled from '@emotion/styled'
import React from 'react'
import about from "../../../assets/about.png"
import aboutmob from "../../../assets/mob.png"
import { Box, Container, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled/macro'
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import the AOS styles
import video from "../../../assets/video.mp4"
import VideoPlayer from '../../Components/VideoPlayer'
// Initialize AOS
AOS.init({
    duration: 1200, // Duration of animations (optional)
    once: true, // Whether animation should happen only once - while scrolling down (optional)
    mirror: false, // Whether elements should animate out while scrolling past them (optional)
});

const SectionWraper = emotionStyled(Box)`
    background-color:#C8EE5B;
    min-height:auto;
    width:100%;
    display:flex;
    padding:40px 30px;
    justify-content:center;
    align-items:center;
    position:relative;
    // border-bottom:50px solid transparent;
    // border-image: linear-gradient(to top,#FF4F01, transparent) 30;
`
const AboutImage = styled.img`
width: 100%;
height:auto;
@media screen and (max-width:599px){
display:none;
}
`
const AboutImageMob = styled.img`
width: 100%;
height:auto;
display:none;
@media screen and (max-width:599px){
display:flex;
}
`
const Videobg = styled.video`
width:100%;
height:auto;
border-radius:10px;
border:2px solid black;

`
const AboutSection = () => {


    return (
        <SectionWraper id="About">
            <Container maxWidth="xl" >
                <Typography data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" className="textStroke" padding="20px 0 40px" variant='h3' fontFamily="kb" textAlign='center' color="#000" textTransform="camelcase" fontSize={{ xs: "42px", sm: "80px" }} >
                    About $PEPAY</Typography>
                <Grid container sx={{ display: "flex", justifyContent: "center", alignItems: { xs: "start", md: "center" } }}>
                    <Grid container item xs={12} md={10} sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 0px 0px", }}>
                        <AboutImage alt="pepay image" src={about} data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-out" data-aos-delay="300" data-aos-offset="0" />
                        <AboutImageMob alt="pepay image" src={aboutmob} />
                    </Grid>
                    <Grid data-aos="fade-up" data-aos-duration="100" data-aos-easing="ease-in-out" data-aos-delay="500" data-aos-offset="0" item xs={12} md={10} sx={{ padding: "20px 0", display: "flex", justifyContent: "center", alignItems: { xs: "start", md: "center" } }}>
                        <VideoPlayer data-aos="fade-up" data-aos-duration="900" data-aos-easing="ease-in-out" data-aos-delay="100" data-aos-offset="0" videoSrc={video} />
                        {/* <Videobg autoPlay muted loop playsInline> <source src={video} type="video/mp4" />  Your browser does not support the video tag. </Videobg> */}
                    </Grid>
                </Grid>
            </Container>
        </SectionWraper >
    )
}

export default AboutSection